.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 10px 162px;
  padding-bottom: 100px;
}

.card {
  position: relative;
  max-width: 300px;
  height: 350px;
  overflow: hidden;
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin: 10px;
  /* background: #ead2b2; */
}

.card:hover {
  transform: scale(1.05);
}

.card-image {
  width: 250px;
  height: 270px;
  object-fit: contain;
}

.card-content {
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 100%;
  height: 25%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  border-top: 1px solid black;
}

.card-title {
  font-size: 20px;
  margin: 0;
  color: black;
}

.card-hover-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .card-hover-content {
  opacity: 1;
  z-index: 1;
}

.card-subtitle {
  font-size: 1.2em;
  margin: 0;
}

.card-description {
  font-size: 1em;
  margin-top: 8px;
}
