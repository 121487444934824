* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

/* Banner-section */
#banner-carousel {
  width: 100%;
}

#hero-carousel {
  width: 100%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  /* background-color: #000; */
  width: 50px;
  height: 50px;
  opacity: 0.8;
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  background-color: #8d5200;
  opacity: 1;
}

.carousel-control-prev-icon:after,
.carousel-control-next-icon:after {
  font-size: 0px;
  color: #fff;
}

/* Service Section */
#service-section {
  width: 100%;
}

.services {
  width: 100%;
  height: 100vh;
  background-color: white;
  /* background: url("./Assets/home-bg.jpg"); */
  /* background-size: contain; */
  display: flex;
}

.humburger-menu {
  display: none !important;
}

@media (max-width: 1020px) {
  .items {
    display: none !important;
  }
  .humburger-menu {
    display: block !important;
  }
}

.arrowHover {
  width: 80px;
  transition: all 0.7s;
  background-color: white;
}

.arrowHover:hover {
  width: 80px;
  background-color: #ffac13c7;
}

.meeting-events-section {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 80px;
  flex-direction: row-reverse;
}

.meeting-events-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
}

.meeting-events-heading {
  margin-bottom: 30px;
  font-size: 26px;
  text-overflow: ellipsis;
  letter-spacing: normal;
  line-height: 1.2;
  font-weight: 600;
  color: black;
}

.meeting-events-img {
  width: 50%;
}

.view-details {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;
}

.view-details-text {
  font-size: 16px;
  text-overflow: ellipsis;
  letter-spacing: normal;
  line-height: 1.2;
  font-weight: 400;
  color: #211551;
}

.view-details-icon {
  width: 8%;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .meeting-events-section {
    flex-direction: column;
    align-items: center;
  }

  .meeting-events-text {
    width: 80%;
    margin-left: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .meeting-events-img {
    width: 80%;
    height: auto;
  }

  .view-details-icon {
    width: 10%;
  }
}

@media (max-width: 480px) {
  .meeting-events-heading {
    font-size: 18px;
  }

  .view-details-icon {
    width: 12%;
  }
}

/* About Section */
#about-section {
  width: 100%;
}

#about-us {
  width: 100%;
  height: 100vh;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.btn41-43 {
  padding: 10px 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.btn-43 {
  border: 2px solid rgb(0, 0, 0);
  z-index: 1;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 200px;
  border-radius: 4px;
  margin-top: 20px;
}

.btn-43:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgb(0, 0, 0);
  transition: all 0.3s ease;
}

.btn-43:hover {
  color: rgb(0, 0, 0);
  color: white;
}

.btn-43:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}

.buttonHover:hover {
  background: white !important;
  color: black !important;
}
/* About-Us Tabs Section */
#about-section-tabs {
  width: 100%;
}

#about-us-tabs {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fixed-bg-content2 {
  position: relative;
  z-index: 2;
  color: white;
  padding: 20px;
  height: 100%;
}

@media (max-width: 1024px) {
  .fixed-bg2 {
    height: auto !important;
  }
}

/* Footer Section */

@media (max-width: 800px) {
  .footer-parent-container {
    flex-direction: column;
  }
}

/* Client-Carousel button */
.btn42-44 {
  padding: 10px 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  background: transparent;
  /* outline: none !important; */
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  /* display: inline-block; */
}

.btn-44 {
  border: 1px solid #fff;
  z-index: 1;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-decoration: none; */
  width: 200px;
  /* border-radius: 4px; */
  margin-top: 20px;
}

.btn-44:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgb(0, 0, 0);
  transition: all 0.3s ease;
}

.btn-44:hover {
  color: white;
}

.btn-44:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}

@media (max-width: 1024px) {
  .our-mission {
    flex-direction: column;
  }
  .our-mission-section {
    width: 90% !important;
  }
  #about-us-tabs {
    height: auto !important;
  }
  #about-us {
    height: auto !important;
  }
  .services {
    height: auto !important;
    margin-bottom: 50px;
  }
  .BannerImageCarousel {
    height: auto !important;
    width: 100% !important;
  }
  .BannerTittleGsap {
    left: 100px !important;
    font-size: 30px !important;

  }
}

@media (max-width: 650px) {
  .aboutUsImage {
    width: 100% !important;
    height: auto !important;
  }
  .css-1wf8b0h-MuiTabs-flexContainer {
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .css-1l4w6pd {
    display: flex;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .ourVisionButton {
    margin: 10px 0 !important;
  }
  .cards-container {
    margin: 0 !important;
  }
}

@media (max-width: 500px) {
  .BannerTittleGsap {
    left: 50px !important;
    font-size: 18px !important;
  }
}
.map-container {
  position: relative;
}

.map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
