.carousel-container5 {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.carousel5 {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide5 {
  display: flex;
  min-width: 100%;
}

.carousel-item5 {
  position: relative;
  flex: 1;
  box-sizing: border-box;
  text-align: center;
  background-color: white;
  border: 1px solid black;
  margin: 5px;
  border-radius: 5px;
  height: 370px;
  width: 270px;
  overflow: hidden;
  border: 1px solid black;
}

.carousel-image5 {
  width: 250px;
  height: 285px;
  object-fit: contain;
  
}

.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content:center;
  align-items: center;
  background-color :black;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: 1.9;
}

.carousel-item5:hover .carousel-overlay {
  opacity: 0.9;
  display: flex;
  flex-direction: column;
}

.carousel-button5 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #0092cb;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.carousel-button5:hover {
  opacity: 1;
}

.carousel-button5.prev5 {
  left: -40px;
}

.carousel-button5.next5 {
  right: -40px;
}

@media (max-width:500px) {
  
  .scroll-arrow {
    display: none !important;
  }
  
}